import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container } from '@components/common'

type NodeAcf = {
  logo: Logo
  companyName: string
  desc: string
}
type Logo = {
  sourceUrl: string
}
type Node = {
  id: string
  title: string
  acf: NodeAcf
}

const ClientList = () => {
  const data = useStaticQuery(graphql`
    query {
      clients: allWpClient {
        nodes {
          title
          acf {
            logo {
              sourceUrl
            }
            companyName
            desc
          }
        }
      }
    }
  `)
  return (
    <section>
      <Container>
        {data.clients.nodes.map((node: Node, index: number) => (
          <div key={`${node.id}-${index}`} className="flex flex-wrap my-gap-xl">
            <div className="md:w-1/4 w-1/2 pr-gap-xl mb-gap-mini">
              <img
                src={node.acf.logo.sourceUrl}
                alt={`Logo for ${node.acf.companyName}`}
                className="max-w-full px-4"
                loading="lazy"
              />
            </div>
            <div className="md:w-3/4 w-full">
              <h2 style={{ fontSize: '20px' }}>{node.acf.companyName}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: node.acf.desc }}
                className="mt-3"
              />
            </div>
          </div>
        ))}
      </Container>
    </section>
  )
}
export default ClientList
